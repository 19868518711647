<template>
    <div class="flex min-h-full bg-v3-gray-300 bg-cover" :style="{  'background-image': 'url(' + require('../../../images/login-image-light.jpg') + ')' }">
        <div class="relative hidden w-0 flex-1 lg:block">

        </div>
        <div class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 sm:w-1/2 bg-white rounded-2xl dark:bg-v3-gray-900">
            <div class="mx-auto w-full max-w-sm" :class="panelClass">
                <slot></slot>
            </div>
        </div>
        <div class="fixed bottom-0 right-0 text-xs flex justify-end mr-4 items-center text-v3-gray-800 dark:text-v3-gray-200">Powered By
            <img v-if="true && mode==='dark'" src="../../../images/logo-header-dark.png" class="ml-1 opacity-80 h-3">
            <img v-else-if="true && (mode==='light' || mode === null)" src="../../../images/logo-header-light.png" class="ml-1 opacity-80 h-3">
            <span v-else class="text-red-500 text-sm bold ml-1 ">TEST</span>
        </div>

    </div>

</template>

<script>
export default {
    data(){
        return {
            'mode': null
        }
    },
    props:{
        panelClass:{
            type: String,
            default: null
        }
    },
    mounted(){
        if(localStorage.getItem('mode')){
            this.mode = localStorage.getItem('mode');
            if(localStorage.getItem('mode') === 'dark') {
                document.getElementsByTagName('html')[0].classList.add('dark');
                this.mode = 'dark';
            }else{
                document.getElementsByTagName('html')[0].classList.remove('dark');
                this.mode = 'light';
            }
        }
    }
}

</script>

<style scoped>

</style>
